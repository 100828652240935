export default {
  heading: 'Open Sans, sanserif',
  body: 'Montserrat, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, serif',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Open Sans:100,200,300,400,500,600,700,800,900'
  ],
  customFamilies: ['Stannum'],
  customUrls: ['https://gonation.biz/fonts/stannum-regular/stannum-regular.css']
}
