export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem'],
      zIndex: '1001'
    },
    '.react-reveal:has(.watch-our-video)': {
      // position: 'absolute',
      border: 'none',
      right: '80px',
      backgroundColor: 'primary',
      padding: '4px 6px',
      fontWeight: 'bold'
      // zIndex: 10000,
    },
    '.react-reveal:last-child > li': {
      color: 'white!important'
    },
    '.smallNavMenu > div': {
      borderTop: '4px double',
      borderBottom: '4px double',
      borderColor: 'dark',

      '.navItem': {
        color: 'dark',
        padding: ['0.25rem', '0.5rem', '0.5rem'],
        a: {
          fontSize: ['.8rem', '.8rem', '.8rem', '.8rem'],
          border: 'none',
          padding: '0rem 0.5rem'
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem 0.75rem 0.75rem 1.5rem', '1rem 1rem 1rem 3rem'],
      width: '100%',
      flexGrow: '1',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        color: 'inherit'
      },
      a: {
        // borderLeft: '4px solid',
        // borderColor: 'secondary',
        // borderRadius: '500px',
        // paddingLeft: '1rem',
      }
    },

    '.navItemDropdown': {
      width: '100%'
    },
    '.navItemDropdownMenu': {
      backgroundColor: 'white',
      width: 'fit-content'
    },
    '.hamburger': {
      border: 'solid 2px',
      borderColor: '#7a2434',
      backgroundColor: '#7a2434',
      height: '50px',
      width: '50px',
      padding: '0.75rem',
      borderRadius: '500px',
      '> div': {
        backgroundColor: 'light'
      }
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      backgroundColor: 'light',
      padding: ['0.5rem', '', '1rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        maxHeight: ['55px', '', '135px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: 'dark',
      padding: ['1rem', '', '2rem']
    },

    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.25rem'],
        width: 'fit-content'
      },
      padding: '0.25rem',
      margin: '0rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        marginRight: '1rem',
        path: {
          fill: 'light',
          width: '25px',
          height: '25px'
        }
      },
      a: {
        justifyContent: 'flex-start',
        fontSize: ['0.8rem', '1rem'],
        padding: ['0.5rem 0rem', '', '1rem 0rem'],
        margin: '0rem'
      }
    },
    '.phoneContainer': {
      paddingBottom: '0.5rem'
    },

    '.containerScrolled': {
      backgroundColor: 'light',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: ['0.25rem', '0.25rem', '0.25rem', '0.25rem'],
      zIndex: '1001'
    },
    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['75px', '135px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '75px']
      }
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'transparent',
    padding: ['1rem 1rem 4rem', '', '2rem 5rem'],
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey'
        }
      }
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start']
    }
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['1.75rem', '2rem', '2.5rem'],
    order: '2',
    color: 'text',
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
    borderTop: '4px double',
    borderBottom: '4px double',
    padding: '0.25rem 0rem'
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: '500',
    fontSize: ['1.25rem', '1.5rem'],
    textTransform: 'uppercase'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    margin: '1rem 1rem 0rem',
    '.content': {
      order: ['', '', '1'],
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: ['', '', '2'],
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ['', '', '75vh']
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: ['', '', '0'],
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      },
      img: {
        height: ['', '', '75vh']
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light',
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '', '3.5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      p: {
        color: 'white',
        margin: '0rem'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    maxHeight: ['', '', '50vh'],
    margin: '1rem',
    width: 'calc(100% - 2rem)',
    '.imageContainer': {
      order: ['2', '', '1'],
      img: {
        objectFit: 'contain'
      }
    },
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', '', '2']
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      borderBottom: '4px double',
      borderColor: 'secondary',
      width: 'fit-content'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'primary',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageCocktails: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide2'
  },

  homepageSlider: {
    padding: '0rem',
    '.slick-dots': {
      display: 'none !important'
    },
    '.textContent': {
      display: 'none'
    }
  },

  homepageBoxes: {
    padding: '0rem',
    'div:nth-of-type(6)': {
      backgroundColor: '#c5c1af'
    },
    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px'
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.55)'
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem']
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem 0rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400'
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem'
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '0rem 2rem 3rem',
      order: '4',
      borderColor: 'white',
      color: 'white',
      width: 'fit-content',
      textTransform: 'uppercase'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'primary',
      borderTop: 'double 4px',
      borderBottom: 'double 4px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem 2rem'],
      padding: '1rem',
      textAlign: 'center',
      fontSize: ['1.5rem', '', '2rem']
    },

    '.menuItemName': {
      color: 'black',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.25rem'],
      marginBottom: '0.25rem'
    },

    '.menuSectionDescription': {
      fontSize: ['0.8rem', '0.9rem', '1rem', '1rem'],
      textAlign: 'center',
      margin: ['1rem 1rem 1.5rem', '', '1rem 1.5rem 2rem']
    },
    '.menuItemDescription': {
      color: 'primary'
    },
    '.menuPriceContainer': {
      // display: 'none',
    },
    '.variantsContainer': {
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.heading': {
      variant: 'customVariants.title',
      margin: '0 auto'
    },
    '.eventItemImage': {
      minHeight: 'unset',
      height: '200px'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    },
    '.modal': {
      backgroundColor: 'background'
    },
    '.eventDaysContainer': {
      opacity: '0.6',
      fontSize: '1rem'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.primary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: 'secondary',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'primary'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1'
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2'
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
